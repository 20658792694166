import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from './services/cookie-service.service';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEs from '@angular/common/locales/es';

// Register the locale data
registerLocaleData(localeEs);

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "assets/i18n/", ".json")
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			}
		}),
		HttpClientModule,
		SharedModule,
		RouterOutlet,
		RouterLink,
		RouterLinkActive,
		RouterModule,
		BrowserAnimationsModule
	],
	providers: [
		provideClientHydration(
			withHttpTransferCacheOptions({
				includePostRequests: true,
			})
		),
		provideHttpClient(withFetch()),
		CookieService,
		{ provide: LOCALE_ID, useValue: 'es' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
