import {
	Component,
	Inject,
	Optional,
	PLATFORM_ID,
	Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getUserLanguage } from '../../languaje';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CookieService } from './services/cookie-service.service';
import { DomSanitizer, TransferState } from '@angular/platform-browser';
import { NavbarTheme } from './modules/shared/navbar/theme';
import { Router } from '@angular/router';
import {
	trigger,
	state,
	transition,
	style,
	animate,
} from '@angular/animations';
import {
	KPIS_TOKEN,
	KPIS_VALUE,
	PROCESSES_COUNTRY_ISO_TOKEN,
	PROCESSES_COUNTRY_ISO_VALUE,
	POST_BY_ID_TOKEN,
	POST_BY_ID_VALUE,
	POSTCATEGORIES_TOKEN,
	POSTCATEGORIES_VALUE,
	POSTS_TOKEN,
	POSTS_VALUE,
	PROCESSES_TOKEN,
	PROCESSES_VALUE,
} from '../tokens';
import { KPI } from './interfaces/kpi';
import { Observable } from 'rxjs';
import { JobUrl } from './modules/job/job.url';
import { TalentUrl } from './modules/talent/talent.url';
import { CookiesUrl } from './modules/cookies/cookies.url';
import { LegalUrl } from './modules/legal/legal.url';
import { BlogUrl } from './modules/blog/blog.url';
import { BlogPost, Category } from './interfaces/blog';
import { JobOffertsUrl } from './modules/jobOfferts/jobOfferts.url';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.sass',
	animations: [
		trigger('animateDestroy', [
			state('void', style({ opacity: '0' })),
			transition('* => void', animate('500ms ease')),
		]),
	],
})
export class AppComponent {
	mandatoryCookiesConsent$?: Observable<boolean>;
	thirdPartyCookiesConsent$?: Observable<boolean>;
	mandatoryCookiesConsent: boolean = true;
	thirdPartyCookiesConsent: boolean = false;

	readonly LIGHT_THEME_VIEWS = [`/${JobUrl.Base}`];
	readonly FOOTER_BACKGROUND_COLORS = new Map<string, string>([
		[`/${JobUrl.Base}`, 'bg-MoradoClaro'],
		[`/${TalentUrl.Base}`, 'bg-AzulOscuro'],
		[`/${CookiesUrl.Base}`, 'bg-AzulOscuro'],
		[`/${LegalUrl.Base}`, 'bg-AzulOscuro'],
		[`/${BlogUrl.Base}`, 'bg-MoradoClaro'],
		[`/${BlogUrl.BasePost}`, 'bg-MoradoClaro'],
		[`/${JobOffertsUrl.Base}`, 'bg-MoradoClaro'],
	]);
	readonly defaultFooterColorClass = 'bg-AzulOscuro';

	constructor(
		public translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
		public cookieService: CookieService,
		private sanitizer: DomSanitizer,
		private _renderer2: Renderer2,
		public router: Router,
		@Optional() @Inject(KPIS_TOKEN) private kpiInjectedData: KPI,
		@Optional() @Inject(PROCESSES_TOKEN) private processesInjectedData: any,
		@Optional() @Inject(POSTS_TOKEN) private postsInjectedData: BlogPost[],
		@Optional()
		@Inject(POSTCATEGORIES_TOKEN)
		private categoriesInjectedData: Category[],
		@Optional()
		@Inject(POST_BY_ID_TOKEN)
		private postByIdInjectedData: BlogPost,
		@Optional()
		@Inject(PROCESSES_COUNTRY_ISO_TOKEN)
		private processesIsoInjectedData: any,
		@Inject(PLATFORM_ID) private platformId: Object,
		private transferState: TransferState,
	) {
		this.translate.addLangs(['en', 'es', 'pt', 'br', 'it', 'fr', 'de']);
		this.translate.reloadLang('en');
		this.translate.reloadLang('pt');
		this.translate.reloadLang('br');
		this.translate.reloadLang('it');
		this.translate.reloadLang('fr');
		this.translate.reloadLang('de');
		this.translate.setDefaultLang('es');
		let storage = this.document.defaultView?.localStorage;

		if (!isPlatformBrowser(this.platformId)) {
			//get messages from server
			if (kpiInjectedData) transferState.set(KPIS_VALUE, kpiInjectedData);
			if (processesInjectedData)
				transferState.set(PROCESSES_VALUE, processesInjectedData);
			if (postsInjectedData)
				transferState.set(POSTS_VALUE, postsInjectedData);
			if (categoriesInjectedData)
				transferState.set(POSTCATEGORIES_VALUE, categoriesInjectedData);
			if (postByIdInjectedData)
				transferState.set(POST_BY_ID_VALUE, postByIdInjectedData);
			if (processesIsoInjectedData)
				transferState.set(
					PROCESSES_COUNTRY_ISO_VALUE,
					processesIsoInjectedData,
				);
		} else {
			this.mandatoryCookiesConsent$ =
				this.cookieService.getMandatoryCookiesConsent();
			this.thirdPartyCookiesConsent$ =
				this.cookieService.getThirdPartyCookiesConsent();

			this.mandatoryCookiesConsent$.subscribe((val) => {
				this.mandatoryCookiesConsent = val;
			});

			this.thirdPartyCookiesConsent$.subscribe((val) => {
				this.thirdPartyCookiesConsent = val;
				if (!this.thirdPartyCookiesConsent) {
					this.cookieService.deleteThirdPartyCookies();
				}
			});
		}

		let userLanguage = getUserLanguage(
			storage ? true : false,
			this.translate.getBrowserLang(),
			this.translate.defaultLang,
		).toLowerCase();
		this.translate.use(userLanguage);
		this.cookieService.setLanguageCookie(userLanguage);
	}

	getGTMUrl() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(
			'https://www.googletagmanager.com/ns.html?id=GTM-P6JLMSF',
		);
	}

	getCurrentTheme(): NavbarTheme {
		return this.isLightView(this.router.url)
			? NavbarTheme.LIGHT
			: NavbarTheme.DARK;
	}

	getFooterBackgroundColorClass(): string {
		for (let [key, value] of this.FOOTER_BACKGROUND_COLORS) {
			if (this.router.url.includes(key)) {
				return value;
			}
		}

		return this.defaultFooterColorClass;
	}

	isLightView(view: string): boolean {
		for (let v of this.LIGHT_THEME_VIEWS) {
			if (view.includes(v)) return true;
		}

		return false;
	}
}
