const AVAILABLE_LANGUAGES = ["es", "en", "pt", "br", "it", "fr", "de"];

const LOCALSTORAGE_LANG_KEY = "USER_LANG";

export function getUserLanguage(storage: boolean,browserLang: string | undefined, defaultLang: string): string {
	// primero comprobamos si el usuario ha seleccionado un idioma en el navegador previamente
	if(storage){
		const savedLanguage = localStorage.getItem(LOCALSTORAGE_LANG_KEY);
		if (savedLanguage) return savedLanguage.toUpperCase();
	}
	// en caso de que no, devolvemos el idioma por defecto del navegador, siempre y cuando esté disponible
	if (browserLang && AVAILABLE_LANGUAGES.includes(browserLang)) return browserLang.toUpperCase();

	// si no hay idioma por defecto en el navegador usamos nuestro idioma por defecto (es)
	return defaultLang.toUpperCase();
}

export function saveLanguage(language: string) {
	localStorage.setItem(LOCALSTORAGE_LANG_KEY, language.toLowerCase());
}
