<app-navbar [theme]="getCurrentTheme()"></app-navbar>
@if (mandatoryCookiesConsent && thirdPartyCookiesConsent) {
	<app-google-tag-manager></app-google-tag-manager>
}
<router-outlet></router-outlet>
<app-footer [backgroundColorClass]="getFooterBackgroundColorClass()"></app-footer>
@if(!mandatoryCookiesConsent){
	<app-cookie-consent-modal 	(acceptAllCookies)="cookieService.acceptMandatoryAndThirdPartyCookies()"
								(acceptMandatoryCookies)="cookieService.acceptMandatoryCookies()"
								(navigateToCookiesPage)="router.navigate(['cookies'])"
								@animateDestroy
								></app-cookie-consent-modal>
}
